import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				AzureSail Yacht Charters
			</title>
			<meta name={"description"} content={"Подорож на розкішному судні"} />
			<meta property={"og:title"} content={"AzureSail Yacht Charters"} />
			<meta property={"og:description"} content={"Подорож на розкішному судні"} />
			<meta property={"og:image"} content={"https://radiogemfire.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://radiogemfire.com/img/5207929.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://radiogemfire.com/img/5207929.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://radiogemfire.com/img/5207929.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://radiogemfire.com/img/5207929.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://radiogemfire.com/img/5207929.png"} />
			<meta name={"msapplication-TileImage"} content={"https://radiogemfire.com/img/5207929.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Наші пропозиції
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			AzureSail Yacht Charters запрошує вас у світ ексклюзивних яхтових послуг, створених для неперевершеної розкоші та комфорту. Наш широкий спектр послуг гарантує, що кожен аспект вашої морської подорожі буде охоплений, дозволяючи вам насолоджуватися безперешкодним та вишуканим плаванням.
			</Text>
		</Section>
		<Section padding="20px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 50px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 50px 0px"
				>
					Послуги:
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Широкий вибір яхт: Вибирайте з нашого різноманітного флоту, де є все: від невеликих вітрильників до великих розкішних яхт.
  <br/><br/>
Повністю обладнані для комфорту: Кожна яхта має розкішні зручності, включаючи просторі салони, сонячні палуби, сучасні кухні та окремі каюти.

						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://radiogemfire.com/img/3.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="cover"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://radiogemfire.com/img/4.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="cover"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Передові навігаційні системи: Впевнена навігація за допомогою новітніх морських технологій, що забезпечують безпечне та ефективне плавання.
  <br/><br/>
Екологічно чисті варіанти: Ми також пропонуємо екологічно чисті яхти, оснащені передовими зеленими технологіями, для мандрівників, які дбають про довкілля.

						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Вечеря для гурманів на морі: Насолоджуйтеся меню, складеним на індивідуальне замовлення досвідченими кухарями, з місцевими морепродуктами та вишуканими кулінарними творіннями.
  <br/><br/>
Водні види спорту та розваги: ​​Удосконаліть свою пригоду за допомогою різних видів діяльності, включаючи снорклінг, дайвінг та катання на водних мотоциклах.
  <br/><br/>
Послуги консьєржа: Наш консьєрж може організувати все: від екскурсій на березі до особистого шопінгу, забезпечуючи ідеальну якість кожної деталі вашої подорожі.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://radiogemfire.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="cover"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://radiogemfire.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="cover"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Індивідуальні маршрути: Створіть свою ідеальну морську подорож за допомогою індивідуальних маршрутів та пропозицій щодо пунктів призначення від нашої команди експертів.
  <br/><br/>
Проведення заходів: Відсвяткуйте особливі події, такі як весілля, ювілеї або корпоративні збори у захоплюючому морському стилі.

						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Ви готові дізнатися більше?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Пориньте в чарівну атмосферу моря разом із чартерною компанією AzureSail Yacht. Зв'яжіться з нами, щоб дізнатися більше про наші послуги та почати планувати свою подорож хвилями. Кожна подорож з нами – це крок до незабутніх спогадів. Відчуйте вершину морської розкоші. Нехай обрій стане вашим провідником.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://radiogemfire.com/img/7.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});